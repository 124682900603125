@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;700&display=swap);
html,
body {
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Raleway" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(/static/media/background.d2945689.jpg) no-repeat center center fixed;
  background-size: cover;
  height: 100%;
}

